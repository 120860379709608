export const mutations = {
  addcontacts(state, contacts) {
    state.contacts = [...contacts];
    // console.log(contacts)
    state.contacts = [...contacts];
  },
  addcontact(state, contact) {
    state.contacts = [...state.contacts, contact];
  },
  replacecontact(state, contact) {
    state.contacts = state.contacts.map((struc) =>
      struc.id === contact.id ? contact : struc
    );
  },
  setcontact(state, contact) {
    state.contact = contact.data;
  },
  addcontactsPage(state, contact) {
    state.contacts = [...contact.content];
    console.log(contact.content);
    state.pagination = {
      pageable: contact.pageable,
      totalElements: contact.totalElements,
      totalPages: contact.totalPages,
    };
  },
};
