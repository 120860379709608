<template>
  <div v-if="showCookieBanner" class="cookie-banner">
    <p>
      Lors de votre visite sur le site, des cookies peuvent être stockés sur votre appareil afin d’optimiser et personnaliser votre navigation. Vous pouvez configurer votre navigateur pour refuser les cookies, mais cela pourrait limiter l'accès à certaines fonctionnalités du site. Pour plus d’informations sur la gestion des cookies, veuillez consulter les pages d’assistance de votre navigateur.
    </p>
    <button @click="acceptCookies">Accepter</button>
    <button @click="cancelCookies">Annuler</button>
  </div>
</template>

<script>
import { setCookie, getCookie } from "../utils/cookie"; // Import des fonctions

export default {
  data() {
    return {
      showCookieBanner: false,
    };
  },
  mounted() {
    // Vérifier si l'utilisateur a déjà accepté les cookies
    const cookieAccepted = getCookie('cookies-accepted');
    if (!cookieAccepted) {
      this.showCookieBanner = true;
    }
  },
  methods: {
    acceptCookies() {
      // Définir un cookie pour marquer que l'utilisateur a accepté
      setCookie('cookies-accepted', 'true', { expires: 365 });
      this.showCookieBanner = false; // Cacher le bandeau
    },
    cancelCookies() {
      // Action lorsque l'utilisateur clique sur "Annuler"
      this.showCookieBanner = false; // Cacher le bandeau
    },
  },
};
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}
.cookie-banner button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 5px;
}
.cookie-banner button:last-child {
  background-color: #f44336; /* Rouge pour le bouton Annuler */
}
</style>
