import Vue from 'vue'
import Vuex from 'vuex'

import annonce from "./annonce";
import contact from "./contact";

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    annonce,
    contact
  }
  
})
