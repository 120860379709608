export const getters = {
  contacts (state) {
    return state.contacts
  },
  contact (state) {
    return state.contact
  },  
  pagination(state){
    return state.pagination
  }
}
