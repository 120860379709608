// import axios from 'axios'
import { post, put, get } from "@/api/api";
import { API_URL } from "@/config/api-config";

export const actions = {
  
  fetchContacts({ commit }, params) {
    console.clear;
    // const { pageNumber = 0, pageSize = state.pagination.size } = params
    return get(`contacts/langue/` + params).then(({ data }) => {
      commit("addcontacts", data);
    });
  },

  fetchContactsPage({ commit }, params) {
    
    console.clear;
    // const { pageNumber = 0, pageSize = state.pagination.size } = params
    return get(`contacts/pageable?` + params).then(({ data }) => {
      commit("addcontactsPage", data);
    });
  },

  fetchContactsFacture({ commit }, params) {
    console.clear;
    // const { pageNumber = 0, pageSize = state.pagination.size } = params
    return get(`contacts/facture/${params.idFacture}?` + params.filte).then(
      ({ data }) => {
        commit("addcontactsPage", data);
      }
    );
  },
  createContact({ commit }, contact) {
    console.clear;
    return post("contacts", contact).then(({ data }) => {
      commit("addcontact", data);
      // console.log("contact created", contact);
    });
  },

  creerContact({ commit }, contact) {
    console.clear;
    return post("contacts", contact).then(({ data }) => {
      // commit("addcontact", data);
      // console.log("contact created", contact);
      return data;
    });
  },
  updateContact({ commit }, contact) {
    console.clear;
    return put(`contacts/${contact.id}`, contact).then(({ data }) => {
      commit("replacecontact", data);
      // console.log("contact updated", contact);
    });
  },
  createOrUpdateContact({ dispatch }, contact) {

    console.clear;
    if (contact.id !== undefined) {
      return dispatch("updateContact", contact);
    } else {
      return dispatch("createContact", contact);
    }
  },
  selectContact({ commit }, contact) {
    commit("setcontact", contact);
  },
  deleteContact({ commit }, contact) {
    console.clear;
    return remove(`/contacts/${contact.id}`, contact).then(() => {
      commit("removecontact", contact);
    });
  },
  
};
